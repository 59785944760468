<template>
  <div style="position: relative; height: calc(100vh - 100px)">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="fire_back_list" @click="handleBack">
      <i class="el-icon-arrow-left"></i>
      <p>返回列表</p>
    </div>

    <div style="border-radius: 10px" ref="myPage">
      <RelationGraph
        style="height: 700px"
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
      >
        <template #node="{ node }">
          <div
            @mouseover="showNodeTips(node, $event)"
            @mouseout="hideNodeTips(node, $event)"
          >
            <div style="height: 80px; line-height: 80px; cursor: pointer">
              {{ node.text }}
            </div>
          </div>
        </template>

        <template #graph-plug>
          <div
            v-if="isShowNodeTipsPanel"
            :style="{
              left: nodeMenuPanelPosition.x + 'px',
              top: nodeMenuPanelPosition.y + 'px',
            }"
            style="
              z-index: 999;
              padding: 10px;
              background-color: #ffffff;
              border: #eeeeee solid 1px;
              box-shadow: 0px 0px 8px #cccccc;
              position: absolute;
            "
          >
            <p style="font-size: 13px">点击即可查看用户行程上链收益详情</p>
          </div>
        </template>
      </RelationGraph>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

export default {
  components: {
    Breadcrumb,
  },

  data() {
    return {
      earningsData: "",
      earningsDataPhoneArea: "",

      BreadcrumbCon: [
        {
          name: "资产审核",
        },
        {
          name: "火财盒",
        },
      ], // 面包屑数据

      graphOptions: {
        backgrounImageNoRepeat: true,
        layouts: [
          // {
          //   label: "中心",
          //   layoutName: "tree",
          //   layoutClassName: "seeks-layout-center",
          //   defaultJunctionPoint: "border",
          //   // distance_coefficient: 0.6,
          //   min_per_width: "200",
          //   max_per_width: "300",
          //   min_per_height: "300",
          // },
        ],

        moveToCenterWhenRefresh: true,
        defaultNodeColor: "rgba(244, 244, 244, 1)",
        defaultNodeFontColor: "rgba(0, 0, 0, 1)",
        defaultNodeBorderColor: "#A0A0A0",
        defaultLineColor: "rgba(154, 154, 154, 1)",
        defaultNodeBorderWidth: 0,
        disableZoom: true,
      },

      isShowCodePanel: false,
      isShowNodeTipsPanel: false,
      nodeMenuPanelPosition: { x: 0, y: 0 },
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.huoCaiHeGetMatchBoxContributionDetail,
      method: "POST",
      params: JSON.stringify({
        userId: this.$route.query.userId * 1,
        activityId: this.$route.query.activityId * 1,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          const __graph_json_data = {
            rootId: res.data.data.rootId,
            nodes: res.data.data.nodes,
            lines: res.data.data.lines || [],
          };

          this.$refs.seeksRelationGraph.setJsonData(
            __graph_json_data,
            (graphInstance) => {
              // 这些写上当图谱初始化完成后需要执行的代码
            }
          );

          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    handleBack() {
      if (this.$route.query.page == "history") {
        this.$router.push("/FireWealthBoxHistory");
      } else if (this.$route.query.page == "vait") {
        this.$router.push("/FireWealthBoxVait");
      }
    },

    showNodeTips(nodeObject, $event) {
      console.log(nodeObject);
      this.currentNode = nodeObject;
      if (nodeObject.data) {
        this.earningsDataPhone = nodeObject.data.phone;
        this.earningsDataPhoneArea = nodeObject.data.phoneArea;
      }

      const _base_position = this.$refs.seeksRelationGraph.getInstance().options
        .fullscreen
        ? { x: 0, y: 0 }
        : this.$refs.myPage.getBoundingClientRect();

      this.isShowNodeTipsPanel = true;
      this.nodeMenuPanelPosition.x = $event.clientX - _base_position.x + 10;
      this.nodeMenuPanelPosition.y = $event.clientY - _base_position.y + 10;
    },
    hideNodeTips(nodeObject, $event) {
      this.isShowNodeTipsPanel = false;
    },

    onNodeClick() {
      this.$router.push({
        path: "/CPLERewardHistory",
        query: {
          phone: this.earningsDataPhone,
          phoneArea: this.earningsDataPhoneArea,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fire_back_list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
